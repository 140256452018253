import { formatDateByDayMonth } from './date';
import { formatAsBRLWithSignal } from './number';
import placeholder from './placeholder';

import APP from 'constants/app';
import OFFER from 'constants/offer';
import { SEARCH_RESULTS_TYPES } from 'constants/searchResults';

const VALID_APPROVED_OFFER_STATUSES = [
  OFFER.STATUSES.PRE_APPROVED,
  OFFER.STATUSES.TOP_OFFER,
  OFFER.STATUSES.APPROVED,
  OFFER.STATUSES.HIDDEN,
  OFFER.STATUSES.RESTRICTED,
  OFFER.STATUSES.ETERNAL,
  OFFER.STATUSES.SCHEDULED,
];

export const formatOfferPageDescription = ({
  storeName,
  storeUrl,
  offerPublishedDate,
  offerPrice,
  offerTitle,
}) => {
  const currStoreName = storeName || storeUrl;

  return `Promoção do dia ${formatDateByDayMonth(
    offerPublishedDate
  )}: ${offerTitle} por R$ ${Math.round(
    offerPrice
  )} em ${currStoreName}. Aproveite essa e outras ofertas já com cupom de desconto!`;
};

export const formatOfferPageTitle = (offerTitle, offerPrice) =>
  `${offerTitle.substring(0, 30)}... R$ ${Math.round(offerPrice)} - ${
    APP.NAME
  }`;

export const formatOfferWishlistDescription = ({
  keywordMin: min,
  keywordMax: max,
  keywordTop,
}) => {
  const [maxPriceInBrl, minPriceInBrl] = [max, min].map((value) =>
    formatAsBRLWithSignal(value)
  );
  const getFormattedOfferType = () => {
    const isFreeOffersAlertOnly = min === 0 && max === 0;

    if (isFreeOffersAlertOnly) {
      return 'Apenas ofertas grátis';
    }

    if (keywordTop) {
      return placeholder('descriptions.onlyLowestPriceOffers');
    }

    return placeholder('titles.allOffers');
  };

  let formattedPriceRange;

  if (min > 0 && min < max) {
    formattedPriceRange = `De ${minPriceInBrl} até ${maxPriceInBrl}`;
  } else if (max > 0) {
    formattedPriceRange = `Até ${maxPriceInBrl}`;
  } else if (min > 0) {
    formattedPriceRange = `A partir de ${minPriceInBrl}`;
  }

  return formattedPriceRange
    ? `${formattedPriceRange}\n${getFormattedOfferType()}`
    : getFormattedOfferType();
};

export const getOfferPageEventCategory = (offerStatusName) =>
  offerStatusName !== OFFER.STATUSES.FINISHED ? 'active' : 'finished';

export const hasBadge = (offerIsHighlight, offerStatusName) =>
  hasVerifiedBadge(offerStatusName) ||
  hasUnverifiedBadge(offerStatusName) ||
  hasHighlightBadge(offerIsHighlight, offerStatusName) ||
  offerStatusName === OFFER.STATUSES.TOP_OFFER ||
  offerStatusName === OFFER.STATUSES.FINISHED;

export const hasHighlightBadge = (offerIsHighlight, offerStatusName) =>
  offerIsHighlight && offerStatusName !== OFFER.STATUSES.FINISHED;

export const hasValidOffersResults = (searchResultsType) =>
  searchResultsType === SEARCH_RESULTS_TYPES.ACTIVE_OFFERS.PARAM ||
  searchResultsType === SEARCH_RESULTS_TYPES.FINISHED_OFFERS.PARAM ||
  searchResultsType === SEARCH_RESULTS_TYPES.ALIAS.PARAM;

export const hasUnverifiedBadge = (offerStatusName) =>
  offerStatusName === OFFER.STATUSES.RECENTS;

export const hasVerifiedBadge = (offerStatusName) => {
  const offerStatusNames = [
    OFFER.STATUSES.WAITING,
    OFFER.STATUSES.FINISHED,
    OFFER.STATUSES.RECENTS,
  ];

  return !offerStatusNames.includes(offerStatusName);
};

export const isAnyOfferActive = (offers) => offers.find(isOfferActive);

export const isInternationalOfferByOfferTags = (offerTags) =>
  offerTags.some(({ name }) => name === 'Internacional');

export const isOfferActive = (offer) =>
  offer?.offerStatusName !== OFFER.STATUSES.FINISHED;

export const isOfferFinished = (offer) =>
  offer?.offerStatusName === OFFER.STATUSES.FINISHED;

export const isOfferCategoryIdFilterActive = (categories, categoryId) =>
  categories
    .map(({ categoryId: filteredCategoryId }) => filteredCategoryId)
    .some((filteredCategoryId) => filteredCategoryId === categoryId);

export const isOfferSubcategoryIdFilterActive = (
  subcategories,
  subcategoryId
) =>
  subcategories
    .map(({ subcategoryId: filteredSubcategoryId }) => filteredSubcategoryId)
    .some((filteredSubcategoryId) => filteredSubcategoryId === subcategoryId);

export const isValidApprovedOffer = (offerStatusName) =>
  VALID_APPROVED_OFFER_STATUSES.includes(offerStatusName);

export const toFormattedOfferCount = (length) => {
  if (length === 0 || !length) {
    return '0 ofertas';
  }

  if (length === 1) {
    return '1 oferta';
  }

  return `${length} ofertas`;
};

export const toFormattedOfferOldPrice = (price, priceType = null) => {
  if (!price) {
    return null;
  }

  if (priceType === OFFER.PRICE_TYPES.STARTING_AT) {
    return 'A partir de';
  }

  return price;
};

export const toFormattedOfferPrice = (price, priceType) => {
  if (priceType === OFFER.PRICE_TYPES.COUPON) return 'Cupom';
  if (priceType === OFFER.PRICE_TYPES.FREE) return 'de graça!';

  return price;
};

export const toFormattedNewOffersText = (offersCount) =>
  offersCount === 1
    ? `Atualize para ver ${offersCount} nova oferta`
    : `Atualize para ver ${offersCount} novas ofertas`;

export const toFormattedNewOffersCount = (offersLength) =>
  offersLength > 99 ? '99+' : offersLength;

export const toSidebarFeaturedOffers = (recommendedOffers, featuredOffers) => {
  if (!recommendedOffers && !featuredOffers) {
    return [];
  }

  if (recommendedOffers.length < OFFER.SIDEBAR_FEATURED_OFFERS_LIMIT) {
    let newFeaturedOffersArray = [];
    newFeaturedOffersArray = [...recommendedOffers, ...featuredOffers];
    return newFeaturedOffersArray.slice(0, OFFER.SIDEBAR_FEATURED_OFFERS_LIMIT);
  }

  return recommendedOffers;
};

export const isPromoniverWordOnOfferTitle = (offerTitle = '') =>
  offerTitle.toLowerCase().includes('promoniver');
